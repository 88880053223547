import {
  Box, Button, Collapse, ListItem,
} from '@mui/material';
import {
  FiCheckSquare,
  FiChevronDown,
  FiChevronRight,
} from 'react-icons/fi';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MenuItemIcon from '@modules/event/components/MenuItemIcon';

export default function MainSidebarItem(props) {
  const {
    active,
    children,
    chip,
    depth,
    module,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...other}
      >
        <Button
          disableRipple
          endIcon={!open
            ? <FiChevronRight />
            : <FiChevronDown />}
          onClick={handleToggle}
          startIcon={<MenuItemIcon icon={icon} name={module} />}
          sx={{
            color: active ? 'secondary.main' : 'neutral.300',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            py: 0.75,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)',
            },
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.300',
            },
            '& .svgIconMask': {
              backgroundColor: active ? 'secondary.main' : 'neutral.300',
            },
            '& .MuiButton-endIcon': {
              color: 'neutral.400',
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse
          in={open}
          sx={{ mt: 0.5 }}
        >
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <NextLink
        href={path}
        legacyBehavior
        passHref
      >
        <Button
          component="a"
          disableRipple
          endIcon={chip}
          startIcon={<MenuItemIcon icon={icon} name={module} />}
          sx={{
            backgroundColor: active && 'rgba(255,255,255, 0.08)',
            borderRadius: 1,
            color: active ? 'secondary.main' : 'neutral.300',
            fontWeight: active && 'fontWeightBold',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            py: 0.75,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.300',
            },
            '& .svgIconMask': {
              backgroundColor: active ? 'secondary.main' : 'neutral.300',
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)',
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
      </NextLink>
    </ListItem>
  );
}

MainSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

MainSidebarItem.defaultProps = {
  active: false,
  children: null,
  icon: null,
  info: null,
  open: false,
  path: null,
};

export const NAV_MENU_ITEM_GRAPHQL_SCHEMA = `
id
referenceId
label_de
label_en
predefinedIconName
predefinedIconLib
customIcon {
  data {
    id
    attributes {
      formats
      height
      name
      previewUrl
      url
      width
    }
  }
}
`;
