import adminModule from '@modules/admin/module';
import asanaModule from '@modules/asana/module';
import eventModule from '@modules/event/module';
import eventsModule from '@modules/events/module';
import accountModule from '@modules/account/module';
import hubspot from '@modules/hubspot/module';

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};

export const DEFAULT_LOGO = {
  light: '/images/default-logo-light.svg',
  dark: '/images/default-logo-dark.svg',
};

export const languages = [
  {
    lang: 'de',
    label: 'Deutsch',
    icon: '/static/icons/de_flag.svg',
  },
  {
    lang: 'en',
    label: 'English',
    icon: '/static/icons/uk_flag.svg',
  },
];

export const routes = {
  default: '/',
  auth: {
    login: '/auth/login',
  },
};

export const modules = [
  eventModule,
  eventsModule,
  asanaModule,
  adminModule,
  accountModule,
  hubspot,
];
