import '../polyfills';
import '../wdyr';
import '../common/i18n';
import 'swiper/css';
import 'swiper/css/pagination';
import 'photoswipe/dist/photoswipe.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useTranslation } from 'react-i18next';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ApolloProvider } from '@apollo/client';
import AppBaseProvider from '@components/AppBaseProvider';
import AuthSuspense from '@components/AuthSuspense';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import * as Sentry from '@sentry/browser';
import NextApp from 'next/app';
import {
  Provider as ReduxProvider,
  useSelector,
} from 'react-redux';
import Router from 'next/router';
import {
  SessionProvider,
  useSession,
} from 'next-auth/react';
import { SnackbarProvider } from 'notistack';
import SplashScreen from '@components/SplashScreen';
import { ThemeProvider } from '@mui/material/styles';
import { createEmotionCache } from '@utils/create-emotion-cache';
import { gtm } from '@lib/gtm';
import nProgress from 'nprogress';
import { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import getConfig from 'next/config';
import { selectCurrentTenant } from '@slices/tenant';
import { gtmConfig } from '@config';
import { store } from '@store';
import { createTheme } from '../common/theme';
import { useApollo } from '../common/store/graphql';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const { publicRuntimeConfig } = getConfig();
LicenseInfo.setLicenseKey(publicRuntimeConfig.MUI_LICENSE);

function App(props) {
  const {
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ReduxProvider store={store}>
        <SessionProvider session={pageProps.session}>
          <ApolloProvider client={apolloClient}>
            <AppContent {...props} />
          </ApolloProvider>
        </SessionProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}

function AppContent(props) {
  const {
    Component,
    pageProps,
    baseProps,
  } = props;
  const { i18n } = useTranslation();
  const { data: session } = useSession();
  const tenant = useSelector(selectCurrentTenant);
  const pageTitle = `${tenant?.attributes.name ?? ''} Veranstaltungsplaner`.trim();
  const getLayout = Component.getLayout ?? ((page) => page);
  const favicon = tenant?.attributes.favicons?.data?.[0]?.attributes.url ?? '/favicon-32.png';

  useEffect(() => {
    Sentry.setUser(session?.user ? {
      id: session.user.id,
      username: session.user.username,
    } : null);
  }, [session?.user]);

  return (
    <>
      <Head>
        <title>
          {pageTitle}
        </title>
        <meta
          content="initial-scale=1, width=device-width"
          name="viewport"
        />
        <link href={favicon} rel="icon" type="image/png" />
      </Head>
      <ThemeProvider
        theme={createTheme({
          direction: 'ltr',
          responsiveFontSizes: true,
          mode: 'light',
          brandThemeLight: tenant?.attributes.brandThemeLight,
          brandThemeDark: tenant?.attributes.brandThemeDark,
        })}
      >
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          maxSnack={3}
        >
          <CssBaseline />
          <LocalizationProvider
            adapterLocale={i18n.language}
            dateAdapter={AdapterLuxon}
          >
            <AuthSuspense fallback={<SplashScreen />}>
              <AppBaseProvider
                {...baseProps}
                fallback={<SplashScreen />}
              >
                {getLayout(
                  <Component {...pageProps} />,
                )}
              </AppBaseProvider>
            </AuthSuspense>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  return {
    ...appProps,
    baseProps: {
      host: typeof window === 'undefined'
        ? getHostname(appContext.ctx.req.headers.host)
        : window.location.hostname,
    },
  };
};

function getHostname(host) {
  if (host.includes(':')) {
    return host.substring(0, host.indexOf(':'));
  }

  return host;
}

export default App;
